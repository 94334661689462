import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { Play, Pause } from 'react-bootstrap-icons';
import "./custom.css";

const ImageCarousel = (props) => {
  
  const [isPlaying, setIsPlaying] = useState(true);

  const handlePlayPauseClick = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="carousel-container">
      <Carousel active={isPlaying} fade>
        {
            props.images.map(
                (image) => <Carousel.Item key={image} interval={3000}>
                                <img className="d-block w-100" src={image} alt="Spicy-kitchen"/>
                                <Carousel.Caption>
                                    <button onClick={handlePlayPauseClick} className="play-pause-button">
                                        {isPlaying ? <Pause /> : <Play />}
                                    </button>
                                </Carousel.Caption>
                            </Carousel.Item>
            )
        }
      </Carousel>
    </div>
  );
};

export default ImageCarousel;