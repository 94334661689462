import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";


const RemoveUserButton = ({ className, userId, isCurrentUser, onUserRemove }) => {
    const handleRemove = () => {
        const confirmDelete = window.confirm('Are you sure you want to remove this user?');

        if (confirmDelete) {
            const requestData = { uid: userId };
            console.log(requestData);

            fetch('https://fireback.madushiranasooriyaportfolio.com/skrestaurant_back/deleteUser.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        alert('User removed successfully!');
                        onUserRemove();
                    } else {
                        alert('Error removing user from HTTP: ' + data.error);
                    }
                })
                .catch(error => {
                    alert('Error removing user from catch: ' + error);
                });

                
        }
    };

    const renderRemoveUserTooltip = (props) => (
        <Tooltip {...props}>Remove User</Tooltip>
    );


    return (
        <OverlayTrigger placement="right" overlay={renderRemoveUserTooltip} >
            <button className={className} onClick={handleRemove} 
                disabled={isCurrentUser}>
                    <FontAwesomeIcon icon={faTrash} />
            </button>
        </OverlayTrigger>
    )
};

export default RemoveUserButton;
