import React, { useState, useRef } from "react";
import { database, storage } from "../config/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { ref as dbRef, push, set } from "firebase/database";

const AddImage = (props) => {
    const [image, setImage] = useState(null);
    const [name, setName] = useState('');
    const fileInputRef = useRef(null);

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
          setImage(e.target.files[0]);
        }
    };
    
    const handleAddImage = async (e) => {
        e.preventDefault();

        if (image, name) {
            try {
                const storageRef = ref(storage, `${props.carouselNamePath}${name}`);
                await uploadBytes(storageRef, image);

                const imageUrl = await getDownloadURL(storageRef);

                const imageRef = dbRef(database, props.carouselName);

                const newImageRef = push(imageRef);
                const newImageKey = newImageRef.key;

                await set(newImageRef, {
                    imageUrl: imageUrl
                });

                alert("Image added successfully!");
                setName('');
                setImage(null);
                fileInputRef.current.value = null;
                props.onImageAdd();

            }catch(error) {
                console.error('Error adding item:', error);

                // Show error message
                alert('Something went wrong. Please try again.');
            }
        }
    }

    return (
        <div className="add-new">
            <h2>ADD {props.imageFor} CAROUSEL IMAGES</h2>
            <form onSubmit={handleAddImage}>
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">Image Name</label>
                    <input type="text" name="name" className="form-control"
                        value={name} onChange={(e) => setName(e.target.value)} required />
                </div>
                <div className="mb-3">
                    <label htmlFor="image" className="form-label">Image</label>
                    <input type="file" className="form-control" accept='image/*'
                        onChange={handleImageChange} ref={fileInputRef} required />
                </div>
                <div className='mt-4 d-flex justify-content-center'>
                    <button type='submit' className='btn-add-new'>
                        ADD ITEM
                    </button>
                </div>
            </form>
        </div>
    )

}

export default AddImage;