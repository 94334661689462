import React from "react";
import { useState, useEffect } from "react";
import { Container, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Navigation from "./Navigation";
import Header from "./Header";
import Register from "./Register";
import { getUsers } from "./GetData";
import { MakeAdmin, MakeUser } from "./ChangeRole";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCrown } from '@fortawesome/free-solid-svg-icons';
import RemoveUserButton from "./RemoveUserButton";
import "./custom.css";
import "./users.css";
import AuthDetails from "./AuthDetails";

const Users = () => {
    const [show, setShow] = useState(false);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const authDetails = AuthDetails();

    const currentUserEmail = authDetails.email;


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const users = await getUsers();
            setUsers(users);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };


    const openAddUserForm = () => {
        setShow(true);
    };

    const closeAddUserForm = () => {
        setShow(false);
    };

    const onUserAdd = () => {
        fetchData();
    }

    const handleUserRemove = () => {
        fetchData();
    }

    const handleChangeRole = () => {
        fetchData();
    }
    
    const renderMakeAdminTooltip = (props) => (
        <Tooltip {...props}>Make Admin</Tooltip>
    );

    const renderMakeUserTooltip = (props) => (
        <Tooltip {...props}>Make User</Tooltip>
    );

    

    return(
        <Container fluid>
            <div className="sticky-top">
                <Header />
                <Navigation activePath="/users"/>
            </div>
            <div className="main">

                <div className="view-users">
                    <div className="btn-new-user">
                        <button onClick={openAddUserForm}>ADD A NEW USER</button>
                        <Modal show={show} onHide={closeAddUserForm} keyboard={false}  centered>
                            <button type="button" className="close-add-user" onClick={closeAddUserForm}>
                                <span>&times;</span>
                            </button>
                            <Register onUserAdd={onUserAdd} />
                        </Modal>
                    </div>
                    
                    {loading ? 
                        null
                     : users.length > 0 ? (
                        <table className="user-list">
                            <thead>
                                <tr>
                                    <th>EMAIL</th>
                                    <th class="d-none d-md-table-cell">FULL NAME</th>
                                    <th>ROLE</th>
                                    <th class="d-none d-md-table-cell">ADDED BY</th>
                                    <th className="action"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user) => (
                                    <tr key={user.id}>
                                        <td>{user.email}</td>
                                        <td class="d-none d-md-table-cell">{user.fullName}</td>
                                        <td>{user.role}</td>
                                        <td class="d-none d-md-table-cell">{user.added_by}</td>
                                        <td>
                                            <OverlayTrigger placement="left"
                                                overlay={user.role === "admin" ? renderMakeUserTooltip : renderMakeAdminTooltip} >
                                                <button
                                                    className="changerole"
                                                    disabled={user.email === currentUserEmail}
                                                    onClick={() => user.role === "admin" ? MakeUser(user.id, handleChangeRole) : MakeAdmin(user.id, handleChangeRole)}
                                                >
                                                    {user.role === "admin" ? (
                                                        <>
                                                            <FontAwesomeIcon icon={faUser} />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <FontAwesomeIcon icon={faCrown} />
                                                        </>
                                                    )}
                                                </button>
                                            </OverlayTrigger>
                                            
                                            <RemoveUserButton className="removeuser" userId={user.id} 
                                                isCurrentUser={user.email === currentUserEmail} onUserRemove={handleUserRemove} />
                                            
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>No users available</p>
                    )}
                </div>
            </div>
        </Container>
        
    );
}

export default Users;