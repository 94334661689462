import { useEffect, useState } from "react";
import { auth } from "../config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { database } from "../config/firebase";
import { get, ref } from "firebase/database";

const AuthDetails = () => {
    const [authUser, setAuthUser] = useState(null);
    const [ userId, setUserId] = useState(null);
    const [fullName, setFullName] = useState('');

    useEffect(() => {
        const listen = onAuthStateChanged(auth, (user) => {
            console.log("state changed");
            if (user) {
                setAuthUser(user.email);
                setUserId(user.uid);
                
                const userRef = ref(database, `users/${user.uid}`);
                
                //Retrieve the user's name from the Realtime Database
                get(userRef)
                    .then((snapshot) => {
                        if (snapshot.exists()) {
                            const userData = snapshot.val();
                            const fullName = userData.fullName;
                            setFullName(fullName);
                        }
                    })
                    .catch((error) => {
                        console.error('Error retrieving user details: ', error);
                    });
            }else {
                setAuthUser(null);
                setFullName('');
            }
        });

        return () => {
            listen();
        }
    }, []);

//{/*authUser ? 
//<><span>{`Welcome ${userName} ${authUser}`}</span></>
//: null*/  authUser}

    return ({email: authUser, fullName: fullName, userId: userId}
        
            
        
    );
};

export default AuthDetails;