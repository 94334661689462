import React, { useState } from 'react';
import { createUserWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth, auth2, database } from '../config/firebase';
import AuthDetails from './AuthDetails';
import { ref, set } from 'firebase/database';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import "./custom.css";
import "./users.css";

const Register = ({onUserAdd}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [role, setRole] = useState('user');
  const [addedBy, setAddedBy] = useState('');
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [validRegister, setValidRegister] = useState(true);
  const authDetails = AuthDetails();
 
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    }else if (name === "password") {
      setPassword(value);
    }else if (name === "fullName") {
        setFullName(value);
    }else if (name === "role") {
        setRole(value);
    }
  }

  const validateForm = () => {
    const newErrors = {};
   
    if (!email) {
        newErrors.email = 'Email is required';
    }
    if (!password) {
        newErrors.password = 'Password is required';
    }
    if (password.length <6){
      newErrors.password = 'Password should contain more than 6 characters';
    }
    if (!fullName) {
        newErrors.fullName = 'Full Name is required';
    }
    
    setErrors(newErrors);
    return newErrors;
  };

  const handleAddUser = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    const errors = validateForm();

    if (Object.keys(errors).length === 0) {
      // Firebase Authentication
      createUserWithEmailAndPassword(auth2, email, password)
        .then((userCredential) => {
          const userId = userCredential.user.uid; // Get the user's unique ID
          signOut(auth2);
    
          // Firebase Realtime Database
          const userRef = ref(database, 'users/' + userId);
    
          // Additional user details
          const userDetails = {
            email: email,
            fullName : fullName,
            role: role,
            added_by: authDetails.email
          };
    
          // Store additional user details in the Realtime Database
          set(userRef, userDetails)
            .then(() => {
              alert('User Added Successfully');
              setEmail('');
              setPassword('');
              setFullName('');
              setRole('user');

              onUserAdd();
            })
            .catch((error) => {
              console.error('Error storing user details in the database: ', error);
            });
        })
        .catch((error) => {
          if (error.message && error.message.includes('auth/email-already-in-use')) {
            setValidRegister(false);
          }
        });

        
    }
    
};

const handlePasswordToggle = () => {
  setShowPassword(!showPassword);
}


  return (
    <div className="add-user">
        <h2>ADD USERS</h2>
        
        <form onSubmit={handleAddUser}>
          { formSubmitted && !validRegister && <p className='error'>This email is already in use!</p>}
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email</label>
            <input type="email" className="form-control" id="email" placeholder="name@example.com"
              name="email" value={email} onChange={handleInputChange} required />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">Password</label>
            <div className="reg-password" >
              <input type={showPassword ? "text" : "password"} className="form-control" id="password" placeholder="*****************"
                name="password" value={password} onChange={handleInputChange} required />
              <button type='button' className='show-reg-password-button' onClick={handlePasswordToggle} >
                  <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
              </button>
            </div>
            {password && password.length < 6 ? <p className='error'>Password should contain more than 6 characters!</p> : null}
          </div>
          <div className="mb-3">
            <label htmlFor="fullName" className="form-label">Full Name</label>
            <input type="text" className="form-control" id="fullName" placeholder="your name"
              name="fullName" value={fullName} onChange={handleInputChange} required />
          </div>
          <div className="mb-3">
              <label htmlFor="role" className="form-label">Role</label>
              <select className="form-select" id="role" name="role" value={role} onChange={handleInputChange} >
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
              </select>
          </div>
          <div className="mb-3">
            <label htmlFor="addedBy" className="form-label">Added By</label>
            <input type="text" className="form-control" id="addedBy"
              name="addedBy" value={authDetails.email} onChange={(e) => setAddedBy(e.target.value)} readOnly />
          </div>

          <div className="mt-4 d-flex justify-content-center">
            <button type="submit" className="btn-register">ADD USER</button>
          </div>
        </form>
  </div>
            
         
  );
};

export default Register;
