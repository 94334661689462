import React, { useState, useRef } from 'react';
import { database, storage } from '../config/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { ref as dbRef, push, set } from 'firebase/database';
import './custom.css';
import './team.css';

const AddTeam = (props) => {
  const [name, setName] = useState('');
  const [companyRole, setCompanyRole] = useState('');
  const [image, setImage] = useState(null);
  const fileInputRef = useRef(null); // Create a ref for the file input

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'name') {
      setName(value);
    }
    if (name === 'companyRole') {
      setCompanyRole(value);
    }
  };

  const handleAddTeamMember = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    if (name && companyRole && image) {
      try {
        // Step 1: Upload image to Firebase Storage
        const storageRef = ref(storage, `${props.teamImagePath}${name}`);
        await uploadBytes(storageRef, image);

        // Step 2: Get the download URL of the uploaded image
        const imageUrl = await getDownloadURL(storageRef);

        // Step 3: Store data in Realtime Database
        const teamMemberRef = dbRef(database, props.teamdb);

        // Push a new item to the database
        const newTeamMemberRef = push(teamMemberRef);
        const newteamMemberKey = newTeamMemberRef.key;

        // Set data for the new item
        await set(newTeamMemberRef, {
          name: name,
          company_role: companyRole,
          imageUrl: imageUrl,
        });

        // Show success message
        alert('Team member added successfully!');

        // Clear the form
        setName('');
        setCompanyRole('');
        setImage(null);
        
        // Manually reset the file input value
        fileInputRef.current.value = null;

        //props.onTeamMemberAdd();
      } catch (error) {
        // Handle errors
        console.error('Error adding item:', error);

        // Show error message
        alert('Something went wrong. Please try again.');
      }
    }
  };

  return (
    <div className='add-new'>
      <h2>ADD TEAM MEMBERS</h2>
      <form onSubmit={handleAddTeamMember}>
        <div className='mb-3'>
          <label htmlFor='name' className='form-label'>
            Name
          </label>
          <input
            type='text'
            className='form-control'
            name='name'
            value={name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className='mb-3'>
          <label htmlFor='companyRole' className='form-label'>
            Company Role
          </label>
          <input
            type='text'
            className='form-control'
            name='companyRole'
            value={companyRole}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className='mb-3'>
          <label htmlFor='image' className='form-label'>
            Image
          </label>
          <input
            type='file'
            className='form-control'
            accept='image/*'
            onChange={handleImageChange}
            ref={fileInputRef} // Set the ref for the file input
          />
        </div>
        <div className='mt-4 d-flex justify-content-center'>
          <button type='submit' className='btn-add-new'>
            ADD ITEM
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddTeam;
