import React, { useState, useEffect, useRef } from "react";
import AuthDetails from "./AuthDetails";
import { database } from "../config/firebase";
import { ref, update } from "firebase/database";
import emailjs from "@emailjs/browser";
import "./custom.css";
import "./messages.css";

const ReplyMessage = ({messageDetails, onReply}) => {
    const [replyMessage, setReplyMessage] = useState('');
    const [message, setMessage] = useState('');
    const authDetails = AuthDetails();
    const loggedInUser = authDetails.email;
    const form = useRef();
 
    useEffect(() => {
        if (messageDetails) {
            setMessage(messageDetails.message || '');
        }
    }, [messageDetails]);
    
    
    

    const sendReply = (e) => {
        e.preventDefault();
        const templateParams = {
            email: messageDetails.email,
            subject: 'Spicy Kitchen',
            userName: messageDetails.userName,
            replyMessage: replyMessage,
            from: loggedInUser,
          };
      
          emailjs.send(
            'service_up0t8os', // Replace with your EmailJS email service ID
            'template_3ntefcq', // Replace with your EmailJS email template ID
            templateParams,
            'J1JSTWcXCPpmp8dL7' // Replace with your EmailJS user ID
          )
            .then(response => {
              console.log('Email sent successfully:', response);
      
              // Now, update Firebase with the replied message and status change
              const messageRef = ref(database, `user_message/${messageDetails.id}`);
              const replyData = {
                replyMessage: replyMessage,
                status: 'Replied: ' + loggedInUser,
              };
      
              update(messageRef, replyData)
                .then(() => {
                  console.log('Database updated successfully');
                  alert('Email sent and database updated successfully');
                  onReply(); // Close the reply modal
                })
                .catch(error => console.error('Error updating Firebase:', error));
            })
            .catch(error => {
              console.error('Error sending email:', error);
              alert('Error sending email:', error);
            });
    }

      return (
        <div className="reply">
            <h2>REPLY TO USER MESSAGE</h2>
            <form onSubmit={sendReply} ref={form} >
                <div className="mb-3">
                    <label htmlFor="message" className="form-label">Message</label>
                    <textarea className="form-control" name="message" 
                        value={message} onChange={(e) => setMessage(e.target.value)} readOnly />
                </div>
                <div className="mb-3">
                    <label htmlFor="replyMessage" className="form-label">Reply</label>
                    <textarea className="form-control" name="replyMessage" 
                        value={replyMessage} onChange={(e) => setReplyMessage(e.target.value)} required />
                </div>
                <div className='mt-4 d-flex justify-content-center'>
                    <button type='submit' className='btn-add-new'>
                        SEND
                    </button>
                </div>
            </form>
        </div>
      )

} 

export default ReplyMessage;