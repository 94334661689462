import React, { useState, useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { auth } from '../config/firebase'; // Import your Firebase configuration

const PrivateRoutes = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    // Show a loading indicator or spinner while checking authentication status
    return <p>Loading...</p>;
  }

  return (
    <>
      {user ? <Outlet /> : <Navigate to='/' />}
    </>
  );
};

export default PrivateRoutes;
