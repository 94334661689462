import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Navigation from "./Navigation";
import Header from "./Header";
import ImageCarousel from "./ImageCarousel";
import { getHomeImages } from "./GetData";
import "./custom.css";
import "./navigation.css";

const Home = () => {
    const [carouselImages, setCarouselImages] = useState([]);

    useEffect(() => {
        const fetchHomeImages = async () => {
            try {
                const images = await getHomeImages();

                const urls = images.map(image => image.imageUrl);
                setCarouselImages(urls);
            } catch (error) {
                console.error(error);
            }
        };

        fetchHomeImages();
    }, []);

    return(
        <Container fluid>
            <div className="sticky-top">
                <Header />
                <Navigation />
            </div>
            <ImageCarousel images={carouselImages} />
            <div className="main"></div>
        </Container>
        
    );
}

export default Home;