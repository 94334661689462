import React, {useState, useEffect} from "react";
import { Container, Modal } from "react-bootstrap";
import Header from "./Header";
import Navigation from "./Navigation";
import AddImage from "./AddImage";
import { getHomeImages, getAboutImages, getBakeryImages } from "./GetData";
import { database } from "../config/firebase";
import { ref, remove } from "firebase/database";
import "./custom.css";
import "./images.css";


const Images = () => {
    const [showAddHomeImg, setShowAddHomeImg] = useState(false);
    const [showAddAboutImg, setShowAddAboutImg] = useState(false);
    const [showAddBakeryImg, setShowAddBakeryImg] = useState(false);
    const [homeImages, setHomeImages] = useState([]);
    const [aboutImages, setAboutImages] = useState([]);
    const [bakeryImages, setBakeryImages] = useState([]);
    const [loading, setLoading] = useState(true);

    const openAddHomeImgForm = () => {
        setShowAddHomeImg(true);
    }

    const closeAddHomeImgForm = () => {
        setShowAddHomeImg(false);
    }

    const openAddAboutImgForm = () => {
        setShowAddAboutImg(true);
    }

    const closeAddAboutImgForm = () => {
        setShowAddAboutImg(false);
    }

    const openAddBakeryImgForm = () => {
        setShowAddBakeryImg(true);
    }

    const closeAddBakeryImgForm = () => {
        setShowAddBakeryImg(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const homeImages = await getHomeImages();
            setHomeImages(homeImages);
            const aboutImages = await getAboutImages();
            setAboutImages(aboutImages);
            const bakeryImages = await getBakeryImages();
            setBakeryImages(bakeryImages);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const onImageAdd = () => {
        fetchData();
    }

    const removeHomeImage = (imageId) => {
        const imageRef = ref(database, 'carouselHome/' +imageId);
        const confirmDelete = window.confirm('Are you sure you want to remove this item?');
        if (confirmDelete) {
            remove(imageRef)
            .then(() => {
                alert("Item removed successfully!");
            })
            .catch((error) => {
                alert("Error deleting item: " + error);
            });
        }

        fetchData();
    }

    const removeAboutImage = (imageId) => {
        const imageRef = ref(database, 'carouselAbout/' +imageId);
        const confirmDelete = window.confirm('Are you sure you want to remove this item?');
        if (confirmDelete) {
            remove(imageRef)
            .then(() => {
                alert("Item removed successfully!");
            })
            .catch((error) => {
                alert("Error deleting item: " + error);
            });
        }

        fetchData();
    }

    const removeBakeryImage = (imageId) => {
        const imageRef = ref(database, 'carouselBakery/' +imageId);
        const confirmDelete = window.confirm('Are you sure you want to remove this item?');
        if (confirmDelete) {
            remove(imageRef)
            .then(() => {
                alert("Item removed successfully!");
            })
            .catch((error) => {
                alert("Error deleting item: " + error);
            });
        }

        fetchData();
    }

    return(
        <Container fluid>
            <div className="sticky-top">
                <Header />
                <Navigation activePath="/images"/>
            </div>
            <div className="main">
                <h2>IMAGES</h2>
                <div className="view-images">
                    <h3>HOME</h3>
                    <div className="btn-new-item">
                        <button onClick={openAddHomeImgForm}>ADD A NEW IMAGE</button>
                        <Modal show={showAddHomeImg} onHide={closeAddHomeImgForm} keyboard={false}  centered>
                            <button type="button" className="close-add-new" onClick={closeAddHomeImgForm}>
                                <span>&times;</span>
                            </button>
                            <AddImage imageFor="HOME" carouselNamePath="carouselHome/" 
                                carouselName="carouselHome" onImageAdd={onImageAdd}  />
                        </Modal>
                    </div>
                    {loading ? null :
                        <div className="images">
                            <div className="row justify-content-center">
                                {homeImages.map((image) => (
                                    <div key={image.id} className="col-md-6">
                                        <div className="image-group">
                                            <img src={image.imageUrl} alt={image.name} />
                                            <button onClick={() => removeHomeImage(image.id)}>REMOVE ITEM</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                </div>
                <div className="view-images">
                    <h3>ABOUT</h3>
                    <div className="btn-new-item">
                        <button onClick={openAddAboutImgForm}>ADD A NEW IMAGE</button>
                        <Modal show={showAddAboutImg} onHide={closeAddAboutImgForm} keyboard={false}  centered>
                            <button type="button" className="close-add-new" onClick={closeAddAboutImgForm}>
                                <span>&times;</span>
                            </button>
                            <AddImage imageFor="ABOUT" carouselNamePath="carouselAbout/" 
                                carouselName="carouselAbout" onImageAdd={onImageAdd}  />
                        </Modal>
                    </div>
                    {loading ? null :
                        <div className="images">
                            <div className="row justify-content-center">
                                {aboutImages.map((image) => (
                                    <div key={image.id} className="col-md-6">
                                        <div className="image-group">
                                            <img src={image.imageUrl} alt={image.name} />
                                            <button onClick={() => removeAboutImage(image.id)}>REMOVE ITEM</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                </div>
                <div className="view-images">
                    <h3>BAKERY</h3>
                    <div className="btn-new-item">
                        <button onClick={openAddBakeryImgForm}>ADD A NEW IMAGE</button>
                        <Modal show={showAddBakeryImg} onHide={closeAddBakeryImgForm} keyboard={false}  centered>
                            <button type="button" className="close-add-new" onClick={closeAddBakeryImgForm}>
                                <span>&times;</span>
                            </button>
                            <AddImage imageFor="Bakery" carouselNamePath="carouselBakery/" 
                                carouselName="carouselBakery" onImageAdd={onImageAdd} />
                        </Modal>
                    </div>
                    {loading ? null :
                        <div className="images">
                            <div className="row justify-content-center">
                                {bakeryImages.map((image) => (
                                    <div key={image.id} className="col-md-6">
                                        <div className="image-group">
                                            <img src={image.imageUrl} alt={image.name} />
                                            <button onClick={() => removeBakeryImage(image.id)}>REMOVE ITEM</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Container>
    );
}

export default Images;