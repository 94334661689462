import React, { useState, useRef } from 'react';
import { database, storage } from '../config/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { ref as dbRef, push, set } from 'firebase/database';
import './custom.css';
import './menu.css';

const AddMenu = (props) => {
  const [itemName, setItemName] = useState('');
  const [itemPrice, setItemPrice] = useState('');
  const [image, setImage] = useState(null);
  const fileInputRef = useRef(null); // Create a ref for the file input

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'itemName') {
      setItemName(value);
    }
    if (name === 'itemPrice') {
      setItemPrice(value);
    }
  };

  const handleAddItem = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    if (itemName && itemPrice && image) {
      try {
        // Step 1: Upload image to Firebase Storage
        const storageRef = ref(storage, `${props.menuImagePath}${itemName}`);
        await uploadBytes(storageRef, image);

        // Step 2: Get the download URL of the uploaded image
        const imageUrl = await getDownloadURL(storageRef);

        // Step 3: Store data in Realtime Database
        const breakfastRef = dbRef(database, props.menuTypedb);

        // Push a new item to the database
        const newItemRef = push(breakfastRef);
        const newItemKey = newItemRef.key;

        // Set data for the new item
        await set(newItemRef, {
          name: itemName,
          price: itemPrice,
          imageUrl: imageUrl,
        });

        // Show success message
        alert('Item added successfully!');

        // Clear the form
        setItemName('');
        setItemPrice('');
        setImage(null);
        
        // Manually reset the file input value
        fileInputRef.current.value = null;

        props.onItemAdd();
      } catch (error) {
        // Handle errors
        console.error('Error adding item:', error);

        // Show error message
        alert('Something went wrong. Please try again.');
      }
    }
  };

  return (
    <div className='add-new'>
      <h2>ADD {props.menuType} MENU ITEM</h2>
      <form onSubmit={handleAddItem}>
        <div className='mb-3'>
          <label htmlFor='itemName' className='form-label'>
            Item Name
          </label>
          <input
            type='text'
            className='form-control'
            name='itemName'
            value={itemName}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className='mb-3'>
          <label htmlFor='itemPrice' className='form-label'>
            Price
          </label>
          <input
            type='text'
            className='form-control'
            name='itemPrice'
            value={itemPrice}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className='mb-3'>
          <label htmlFor='image' className='form-label'>
            Image
          </label>
          <input
            type='file'
            className='form-control'
            accept='image/*'
            onChange={handleImageChange}
            ref={fileInputRef} // Set the ref for the file input
          />
        </div>
        <div className='mt-4 d-flex justify-content-center'>
          <button type='submit' className='btn-add-new'>
            ADD ITEM
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddMenu;
