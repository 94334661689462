import React, { useState, useEffect } from "react";
import { Container, Modal } from "react-bootstrap";
import Header from "./Header";
import Navigation from "./Navigation";
import { database } from "../config/firebase";
import { ref, set } from "firebase/database";
import { getContactDetails } from "./GetData";
import "./custom.css";
import "./contact.css";

const Contact = () => {
  const [address, setAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [facebook, setFacebook] = useState('');
  const [email, setEmail] = useState('');
  const [contactDetails, setContactDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [showEditForm, setShowEditForm] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const contactDetailsData = await getContactDetails();
      setContactDetails(contactDetailsData);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (contactDetails) {
        setAddress(contactDetails.address || '');
        setPhoneNumber(contactDetails.phoneNumber || '');
        setFacebook(contactDetails.facebook || '');
        setEmail(contactDetails.email || '');

    }
  }, [contactDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "address") {
      setAddress(value);
    }
    if (name === "phoneNumber") {
      setPhoneNumber(value);
    }
    if (name === "facebook") {
      setFacebook(value);
    }
    if (name === "email") {
      setEmail(value);
    }
  };

  const handleOpenEditForm = () => {
    setShowEditForm(true);
  };

  const handleCloseEditForm = () => {
    setShowEditForm(false);
  };

  const handleChangeDetails = async (e) => {
    e.preventDefault();
    
      try {
      const contactRef = ref(database, 'contact/');

      await set(contactRef, {
        address: address || contactDetails.address,
        phoneNumber: phoneNumber || contactDetails.phoneNumber,
        facebook: facebook || contactDetails.facebook,
        email: email || contactDetails.email,
      });

      alert('Contact details changed successfully!');
      setShowEditForm(false);
      fetchData();
      } catch (error) {
      console.error('Error changing contact details:', error);
      alert('Something went wrong. Please try again.');
      }
    
  };

  return (
    <Container fluid>
      <div className="sticky-top">
        <Header />
        <Navigation activePath="/contact" />
      </div>
      <div className="main">
        <h2>CONTACT DETAILS</h2>
        <div className="view-contact">
          {loading ? null : (
            <div className="contact">
              <p>
                <strong>Address:</strong> {contactDetails.address}
              </p>
              <p>
                <strong>Phone Number:</strong> {contactDetails.phoneNumber}
              </p>
              <p>
                <strong>Facebook Link:</strong>
                <a href={contactDetails.facebook} target="_blank" rel="noreferrer">
                  {contactDetails.facebook}
                </a>
              </p>
              <p>
                <strong>Email Address:</strong> {contactDetails.email}
              </p>
            </div>
          )}
            <button onClick={handleOpenEditForm} className="btn-change-details">CHANGE</button>
            <Modal show={showEditForm} onHide={handleCloseEditForm} keyboard={false}  centered>
                    <button type="button" className="close-add-new" onClick={handleCloseEditForm}>
                        <span>&times;</span>
                    </button>
                    <div className="add-new">
                        <h2>CHANGE CONTACT DETAILS</h2>
                        <form onSubmit={handleChangeDetails}>
                            <div className="mb-3">
                                <label htmlFor="address" className="form-label">Address</label>
                                <input type="text" className="form-control" name="address"
                                    value={address} onChange={handleInputChange}  />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                                <input type="text" className="form-control" name="phoneNumber"
                                    value={phoneNumber} onChange={handleInputChange}  />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="facebook" className="form-label">Facebook Link</label>
                                <input type="text" className="form-control" name="facebook"
                                    value={facebook} onChange={handleInputChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input type="text" className="form-control" name="email"
                                    value={email} onChange={handleInputChange}  />
                            </div>
                            <div className='mt-4 d-flex justify-content-center'>
                                <button type="submit" className="btn-add-new">CHANGE</button>
                            </div>
                        </form>
                    </div>
            </Modal>
        </div>
      </div>

    </Container>
  );
};




/*const Contact = () => {
    const [address, setAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [facebook, setFacebook] = useState('');
    const [email, setEmail] = useState('');
    const [contactDetails, setContactDetails] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchData();
      }, []);
    
      const fetchData = async () => {
        try {
          const contactDetailsData = await getContactDetails();
          setContactDetails(contactDetailsData);
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "address") {
            setAddress(value);
        }
        if (name === "phoneNumber" ) {
            setPhoneNumber(value);
        }
        if (name === "facebook") {
            setFacebook(value);
        }
        if (name === "email") {
            setEmail(value);
        }
    }

    const handleChangeContact = async (field) => {
        try {
            const contactRef = ref(database, `contact/${field}`);
            await set(contactRef, field === 'address' ? address : field === 'phoneNumber' ? phoneNumber 
                : field === 'facebook' ? facebook : email);
            const name = field === "address" ? "Address" : field === "phoneNumber" ? "Phone Number"
                            : field === "facebook" ? "Facebook Link" : "Email";
            alert(`${name} changed successfully!`);
            setAddress('');
            setPhoneNumber('');
            setFacebook('');
            setEmail('');
            fetchData();
        } catch (error) {
            console.error('Error changing contact details:', error);
            alert('Something went wrong. Please try again.');
        }
    }

    return(
        <Container fluid>
            <div className="sticky-top">
                <Header />
                <Navigation activePath="/contact"/>
            </div>
            <div className="main">
                <h2>CONTACT DETAILS</h2>
                <div className="view-contact" >
                    {loading ? null :
                        <div className="contact">
                            <p><strong>Address:</strong> {contactDetails.address}</p>
                            <p><strong>Phone Number:</strong> {contactDetails.phoneNumber}</p>
                            <p><strong>Facebook Link:</strong> {contactDetails.facebook}</p>
                            <p><strong>Email Address:</strong> {contactDetails.email}</p>
                        </div>
                    }
                </div>
                <div className="change-contact">
                    <h3>Change Contact Details</h3>
                    <div className="row mb-3">
                        <label htmlFor="address" className="col-lg-3">Address</label>
                        <input type="text" className="col-lg-6 mr-1" name="address" value={address} onChange={handleInputChange} ></input>
                        <button className="col-lg-2" onClick={() => handleChangeContact("address")} >Change</button>
                    </div>
                    <div className="row mb-3">
                        <label htmlFor="phoneNumber" className="col-lg-3">Phone Number</label>
                        <input type="text" className="col-lg-6 mr-1" name="phoneNumber" value={phoneNumber} onChange={handleInputChange} ></input>
                        <button className="col-lg-2" onClick={() => handleChangeContact("phoneNumber")} >Change</button>
                    </div>
                    <div className="row mb-3">
                        <label htmlFor="facebook" className="col-lg-3">Facebook Link</label>
                        <input type="text" className="col-lg-6 mr-1" name="facebook" value={facebook} onChange={handleInputChange} ></input>
                        <button className="col-lg-2" onClick={() => handleChangeContact("facebook")} >Change</button>
                    </div>
                    <div className="row mb-3">
                        <label htmlFor="email" className="col-lg-3">Email Address</label>
                        <input type="email" className="col-lg-6 mr-1" name="email" value={email} onChange={handleInputChange} ></input>
                        <button className="col-lg-2" onClick={() => handleChangeContact("email")} >Change</button>
                    </div>
                </div>
            </div>
        </Container>
    );
}*/

export default Contact;