import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import Dropdown_custom from "./Dropdown_custom";
import AuthDetails from "./AuthDetails";
import { getDatabase } from "firebase/database";
import { onAuthStateChanged } from "firebase/auth";
import { ref, onValue } from "firebase/database";
import logo from '../images/logo.png';
import "./custom.css";
import "./navigation.css";


const Navigation = (props) => {
    const [userRole, setUserRole] = useState(null);
    const navigate = useNavigate();
    const authDetails = AuthDetails();
    const currentUserId = authDetails.userId;
    const db = getDatabase();

    /*useEffect(() => {
                const userRoleRef = ref(db, 'users/' + currentUserId + '/role/');
                onValue(userRoleRef, (snapshot) => {
                    const role = snapshot.val();
                    setUserRole(role);
                });
    }, []);*/
    
    useEffect(() => {
        const userRoleRef = ref(db, 'users/' + currentUserId + '/role/');
        const fetchUserRole = async () => {
            return new Promise((resolve, reject) => {
                onValue(userRoleRef, (snapshot) => {
                  const data = snapshot.val();
                  if (data) {
                    setUserRole(data);
                    resolve(data);
                  } else {
                    reject('No data available');
                  }
                });
              });
        };

        fetchUserRole()
        .then((userRole) => {
            console.log('User Role:', userRole);
        })
        .catch((error) => {
            console.error('Error fetching user role:', error);
        });
    
    }, [currentUserId]);    

    const drop_items = ["Breakfast", "Lunch", "Dinner"];
    const drop_items_target = ["/breakfast", "/lunch", "/dinner"];
    const drop_classNames = {
                                title: "custom-navbar-text dropdown_title",
                                item: "custom-navbar-text dropdown_title dropdown_item"
    }

    const navigateToUsers = () => {
        console.log(userRole);
        if (userRole === "admin") {
            navigate("/users");
        } else {
            alert("Permission Denied: You do not have the necessary permissions.");
        }
    };

 
    return (
        <Navbar variant="dark" expand="lg" className="custom-navbar-bg">
            <Navbar.Brand href="/home">
                <img src={logo} width="70px" height="70px" className="d-inline-block align-center" alt="logo.jpg" />
                <span className="custom-brand">Spicy Kitchen</span> 
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
                
                <Nav.Link onClick={navigateToUsers} className={props.activePath === "/users" ? "active" : ""}>
                    <span className="custom-navbar-text">MANAGE USERS</span>
                </Nav.Link>
                <Dropdown_custom drop_title="MENUS" drop_items={drop_items} drop_items_target={drop_items_target} 
                    classNames={drop_classNames} activePath={props.activePath === "/menu" ? "active" : ""}/>
                <Nav.Link href="/team" className={props.activePath === "/team" ? "active" : ""}>
                    <span className="custom-navbar-text">TEAM</span>
                </Nav.Link>
                <Nav.Link href="/images" className={props.activePath === "/images" ? "active" : ""}>
                    <span className="custom-navbar-text">IMAGES</span>
                </Nav.Link>
                <Nav.Link href="/contact" className={props.activePath === "/contact" ? "active" : ""}>
                    <span className="custom-navbar-text">CONTACT</span>
                </Nav.Link>
                <Nav.Link href="/messages" className={props.activePath === "/messages" ? "active" : ""}>
                    <span className="custom-navbar-text">USER MESSAGES</span>
                </Nav.Link>
                <Nav.Link href="/contact" className={`d-lg-none ${props.activePath === "/contact" ? "active" : ""}`}>
                    <span className="custom-navbar-text">CONTACT</span>
                </Nav.Link>
                
                
                
            </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

   export default Navigation;

   