import React from "react";
import { Container, Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import Header from "./Header";
import Navigation from "./Navigation";
import { getDinnerMenu } from "./GetData";
import { database } from "../config/firebase";
import { ref, remove } from "firebase/database";
import "./custom.css";
import "./menu.css";
import AddMenu from "./AddMenu";

const Dinner = () => {
    const [show, setShow] = useState(false);
    const [dinnerItems, setDinnerItems] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const dinner = await getDinnerMenu();
            setDinnerItems(dinner);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const openAddItemForm = () => {
        setShow(true);
    };

    const closeAddItemForm = () => {
        setShow(false);
    };

    const onItemAdd = () => {
        fetchData();
    }

    const handleRemoveItem = (itemId) => {
        const itemRef = ref(database, 'dinnerItems/' +itemId);
        const confirmDelete = window.confirm('Are you sure you want to remove this item?');
        if (confirmDelete) {
            remove(itemRef)
            .then(() => {
                alert("Item removed successfully!");
            })
            .catch((error) => {
                alert("Error deleting item: " + error);
            });
        }

        fetchData();
    }

    return (
        <Container fluid>
            <div className="sticky-top">
                <Header />
                <Navigation activePath="/menu"/>
            </div>
            <div className="main">
                <h2>DINNER MENU</h2>
                <div className="view-items">
                    <div className="btn-new-item">
                        <button onClick={openAddItemForm}>ADD A NEW ITEM</button>
                        <Modal show={show} onHide={closeAddItemForm} keyboard={false}  centered>
                            <button type="button" className="close-add-new" onClick={closeAddItemForm}>
                                <span>&times;</span>
                            </button>
                            <AddMenu menuType="DINNER" menuImagePath="dinnerImages/" 
                                menuTypedb="dinnerItems" onItemAdd={onItemAdd} />
                        </Modal>
                    </div>
                    {loading ? null :
                        <div className="menu">
                            <div className="row justify-content-center">
                                {dinnerItems.map((item) => (
                                    <div key={item.id} className="col-sm-6 col-md-4 col-lg-3">
                                        <div className="image-group">
                                            <img src={item.imageUrl} alt={item.name} />
                                            <h5>{item.name}</h5>
                                            <h6>{item.price}</h6>
                                            <button onClick={() => handleRemoveItem(item.id)}>REMOVE ITEM</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Container>
    )
};


export default Dinner;