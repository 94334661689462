import React from "react";
import { useState, useEffect } from "react";
import { Container, Modal } from "react-bootstrap";
import Navigation from "./Navigation";
import Header from "./Header";
import { getMessages } from "./GetData";
import "./custom.css";
import "./messages.css";
import ReplyMessage from "./ReplyMessage";

const Messages = () => {
    const [messages, setMessages] = useState([]);
    const [showReplyForm, setShowReplyForm] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [loading, setLoading] = useState(true);
    

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const messages = await getMessages();
            setMessages(messages);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const openReplyForm = (message) => {
        setShowReplyForm(true);
        setSelectedMessage(message);
    }

    const closeReplyForm = () => {
        setShowReplyForm(false);
        setSelectedMessage(null);
    }

    const onReply = () => {
        fetchData();
        setSelectedMessage(null);
        setShowReplyForm(false);
    }


    

    

    return(
        <Container fluid>
            <div className="sticky-top">
                <Header />
                <Navigation activePath="/messages"/>
            </div>
            <div className="main">

                <div className="view-messages">
                    <h2>USER MESSAGES</h2>
                    
                    {loading ? 
                        null
                     : messages.length > 0 ? (
                        <table>
                            <thead>
                                <tr>
                                    <th>NAME</th>
                                    <th>EMAIL</th>
                                    <th>PHONE NUMBER</th>
                                    <th>MESSAGE</th>
                                    <th>REPLY MESSAGE</th>
                                    <th>STATUS</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {messages.map((message) => (
                                    <tr key={message.id}>
                                        <td>{message.userName}</td>
                                        <td>{message.email}</td>
                                        <td>{message.phoneNumber}</td>
                                        <td>{message.message}</td>
                                        <td>{message.replyMessage}</td>
                                        <td>{message.status}</td>
                                        <td>
                                            <button onClick={() => openReplyForm(message)} className="btn-reply">
                                                Reply
                                            </button>
                                            <Modal show={showReplyForm} onHide={closeReplyForm} keyboard={false}  centered>
                                                <button type="button" className="close-add-new" onClick={closeReplyForm}>
                                                    <span>&times;</span>
                                                </button>
                                                <ReplyMessage onReply={onReply} messageDetails={selectedMessage} />
                                            </Modal>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>No users available</p>
                    )}
                </div>
            </div>
        </Container>
        
    );
}

export default Messages;