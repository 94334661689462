// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAiKeMtndXjfkoW0MG4rzBnCwRJx7wyAPo",
  authDomain: "restaurant-spicy-kitchen.firebaseapp.com",
  databaseURL: "https://restaurant-spicy-kitchen-default-rtdb.firebaseio.com",
  projectId: "restaurant-spicy-kitchen",
  storageBucket: "restaurant-spicy-kitchen.appspot.com",
  messagingSenderId: "1051758614428",
  appId: "1:1051758614428:web:f3e8c5f3db54d79ab6da59"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const secondaryApp = initializeApp(firebaseConfig, "Secondary");

export const database = getDatabase(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const auth2 = getAuth(secondaryApp);