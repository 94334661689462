import React, { useState, useEffect } from "react";
import { Container, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getTeamMembers } from "./GetData";
import { database } from "../config/firebase";
import { ref, remove } from "firebase/database";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import Header from "./Header";
import Navigation from "./Navigation";
import "./custom.css";
import "./team.css";
import AddTeam from "./AddTeam";
import EditTeamMember from "./EditTeamMember";

const Team = () => {
  const [showAddMemberForm, setShowAddMemberForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const team = await getTeamMembers();
      setTeamMembers(team);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const openAddMemberForm = () => {
    setShowAddMemberForm(true);
    setSelectedMember(null);
  };

  const closeAddMemberForm = () => {
    setShowAddMemberForm(false);
    setSelectedMember(null);
  };

  const openEditMemberForm = (member) => {
    setShowEditForm(true);
    setSelectedMember(member);
  };

  const closeEditMemberForm = () => {
    setShowEditForm(false);
    setSelectedMember(null);
  };

  const onMemberAdd = () => {
    fetchData();
  };

  const onMemberEdit = () => {
    fetchData();
    setSelectedMember(null);
    setShowEditForm(false);
  };

  const handleRemoveMember = (memberId) => {
    const teamMemberRef = ref(database, 'teamMembers/' + memberId);
    const confirmDelete = window.confirm('Are you sure you want to remove this member?');
    if (confirmDelete) {
      remove(teamMemberRef)
        .then(() => {
          alert("Team member removed successfully!");
        })
        .catch((error) => {
          alert("Error deleting member: " + error);
        });
    }

    fetchData();
  };

  const renderEditMemberTooltip = (props) => (
    <Tooltip {...props}>Edit Member</Tooltip>
  );

  const renderRemoveMemberTooltip = (props) => (
    <Tooltip {...props}>Remove Member</Tooltip>
  );

  return (
    <Container fluid>
      <div className="sticky-top">
        <Header />
        <Navigation activePath="/team"/>
      </div>
      <div className="main">
        <h2>OUR TEAM</h2>
        <div className="view-team">
          <div className="btn-new-member">
            <button onClick={openAddMemberForm}>ADD A NEW TEAM MEMBER</button>
            <Modal show={showAddMemberForm} onHide={closeAddMemberForm} keyboard={false} centered>
              <button type="button" className="close-add-new" onClick={closeAddMemberForm}>
                <span>&times;</span>
              </button>
              <AddTeam
                teamImagePath="teamMembersImages/"
                teamdb="teamMembers"
                onMemberAdd={onMemberAdd}
              />
            </Modal>
          </div>
          {loading ? null : (
            <div className="team">
              <div className="row justify-content-center">
                {teamMembers.map((member) => (
                  <div key={member.id} className="col-sm-12 col-md-6 col-lg-4">
                    <div className="image-group">
                      <img src={member.imageUrl} alt={member.name} />
                      <h5>{member.name}</h5>
                      <h6>{member.company_role}</h6>
                      <div className="button-group">
                        <div>
                          <OverlayTrigger placement="left" overlay={renderEditMemberTooltip}>
                            <button className="btn-edit" onClick={() => openEditMemberForm(member)}>
                              <FontAwesomeIcon icon={faPencilAlt} />
                            </button>
                          </OverlayTrigger>
                          <Modal show={showEditForm} onHide={closeEditMemberForm} keyboard={false} centered>
                            <button type="button" className="close-add-new" onClick={closeEditMemberForm}>
                              <span>&times;</span>
                            </button>
                            <EditTeamMember
                              member={selectedMember}
                              teamImagePath="teamImages/"
                              teamdb="teamMembers"
                              onMemberEdit={onMemberEdit}
                            />
                          </Modal>
                        </div>
                        <OverlayTrigger placement="right" overlay={renderRemoveMemberTooltip}>
                          <button onClick={() => handleRemoveMember(member.id)} className="btn-remove">
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Team;
