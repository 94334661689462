import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../config/firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import './custom.css';
import './login.css';

const LoginPopup = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [show, setShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [validLogin, setValidLogin] = useState(true);
  const [resetPasswordEmail, setResetPasswordEmail] = useState('');
  const [resetPassword, setResetPassword] = useState(false);

  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const handleShow = () => {
    setShow(true);
    setResetPassword(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    }else if (name === 'resetPasswordEmail') {
      setResetPasswordEmail(value)
    }
  };

  const handleLogIn = (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        navigate('/home');
      })
      .catch((error) => {
        if (error.code === 'auth/invalid-login-credentials') {
          setValidLogin(false);
        }
      });
  };

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleForgotPassword = () => {
    setResetPassword(true);
  };

  const handleSendResetEmail = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, resetPasswordEmail);
      alert('Password reset email sent. Check your inbox.');
      handleClose(); // Close the modal after sending reset email
    } catch (error) {
      alert('Error sending password reset email: ' + error.message);
    }
  };
  

  return (
    <div className="login">
      <button type="button" className="btn-login-form mr-4" onClick={handleShow}>
        <span className="">Log In</span>
      </button>

      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <div className="login-form">
          <div className="login-popup">
            <button type="button" className="close" onClick={handleClose}>
              <span>&times;</span>
            </button>
            <div className="login-content">
              {resetPassword ? (
                <div>
                  <h3>Reset Password</h3>
                  <form onSubmit={handleSendResetEmail}>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="resetPasswordEmail"
                        placeholder="name@example.com"
                        name="resetPasswordEmail"
                        value={resetPasswordEmail}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <button type="submit" className="btn-login">
                        Send Email
                      </button>
                    </div>
                  </form>
                  <div className="mt-5">
                      <span className="link link-login" onClick={() => setResetPassword(false)}>
                        Go to Log In
                      </span>
                  </div>
                </div>
              ) : (
                <div>
                  <h3>Log In</h3>
                  <form onSubmit={handleLogIn}>
                    {formSubmitted && !validLogin && <p className='login-error'>Invalid email or password</p>}
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="name@example.com"
                        name="email"
                        value={email}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <div className="log-password">
                        <input
                          type={showPassword ? 'text' : 'password'}
                          className="form-control"
                          id="password"
                          placeholder="*****************"
                          name="password"
                          value={password}
                          onChange={handleInputChange}
                          required
                        />
                        <button type="button" className="show-log-password-button" onClick={handlePasswordToggle}>
                          <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                        </button>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button type="submit" className="btn-login">
                        Login
                      </button>
                    </div>
                  </form>
                  <div className="mt-4">
                      <span className="link link-login" onClick={handleForgotPassword}>
                        Reset Password
                      </span>
                  </div>
                  <div className='mt-4 testing'>
                    <h6>For Testing Purpose Only</h6>
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Email</th>
                          <th>Password</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Admin: </td>
                          <td>admin@gmail.com</td>
                          <td>admin@123</td>
                        </tr>
                        <tr>
                          <td>User: </td>
                          <td>user@gmail.com</td>
                          <td>user@123</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LoginPopup;
