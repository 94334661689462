import { database as db } from "../config/firebase";
import { ref, onValue } from "firebase/database";


const usersRef = ref(db, 'users/'); // Reference to the "users" node
const breakfastMenuRef = ref(db, 'breakfastItems/'); //Ref to the breakfastItems node
const lunchMenuRef = ref(db, 'lunchItems/');
const dinnerMenuRef = ref(db, 'dinnerItems/');
const teamMembersRef = ref(db, 'teamMembers/');
const contactRef = ref(db, 'contact/');
const carouselHomeRef = ref(db, 'carouselHome/');
const carouselAboutRef = ref(db, 'carouselAbout/');
const carouselBakeryRef = ref(db, 'carouselBakery/');
const messagesRef = ref(db, 'user_message');

const getUsers = async () => {
  return new Promise((resolve, reject) => {
    onValue(usersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // Convert the data into an array of users with their IDs
        const users = Object.keys(data).map((userId) => ({
          id: userId, // Include the user's ID
          ...data[userId], // Include the user's data
        }));
        resolve(users);
      } else {
        reject('No data available');
      }
    });
  });
};

const getBreakfastMenu = async () => {
  return new Promise((resolve, reject) => {
    onValue(breakfastMenuRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // Convert the data into an array of users with their IDs
        const breakfastItems = Object.keys(data).map((itemId) => ({
          id: itemId, // Include the user's ID
          ...data[itemId], // Include the user's data
        }));
        resolve(breakfastItems);
      } else {
        reject('No data available');
      }
    });
  });
};

const getLunchMenu = async () => {
  return new Promise((resolve, reject) => {
    onValue(lunchMenuRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // Convert the data into an array of users with their IDs
        const lunchItems = Object.keys(data).map((itemId) => ({
          id: itemId, // Include the user's ID
          ...data[itemId], // Include the user's data
        }));
        resolve(lunchItems);
      } else {
        reject('No data available');
      }
    });
  });
};

const getDinnerMenu = async () => {
  return new Promise((resolve, reject) => {
    onValue(dinnerMenuRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // Convert the data into an array of users with their IDs
        const dinnerItems = Object.keys(data).map((itemId) => ({
          id: itemId, // Include the user's ID
          ...data[itemId], // Include the user's data
        }));
        resolve(dinnerItems);
      } else {
        reject('No data available');
      }
    });
  });
};

const getTeamMembers = async () => {
  return new Promise((resolve, reject) => {
    onValue(teamMembersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // Convert the data into an array of users with their IDs
        const teamMembers = Object.keys(data).map((memberId) => ({
          id: memberId, // Include the user's ID
          ...data[memberId], // Include the user's data
        }));
        resolve(teamMembers);
      } else {
        reject('No data available');
      }
    });
  });
};

const getContactDetails = async () => {
  return new Promise((resolve, reject) => {
    onValue(contactRef, (snapshot) => {
      const data = snapshot.val();

      if (data) {
        const { address, phoneNumber, facebook, email } = data;

        resolve({
          address: address || '',
          phoneNumber: phoneNumber || '',
          facebook: facebook || '',
          email: email || '',
        });
      } else {
        reject('No contact details available');
      }
    });
  });
};

const getHomeImages = async () => {
  return new Promise((resolve, reject) => {
    onValue(carouselHomeRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // Convert the data into an array of users with their IDs
        const homeImages = Object.keys(data).map((imageId) => ({
          id: imageId, 
          ...data[imageId], 
        }));
        resolve(homeImages);
      } else {
        reject('No data available');
      }
    });
  });
};

const getAboutImages = async () => {
  return new Promise((resolve, reject) => {
    onValue(carouselAboutRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // Convert the data into an array of users with their IDs
        const aboutImages = Object.keys(data).map((imageId) => ({
          id: imageId, 
          ...data[imageId], 
        }));
        resolve(aboutImages);
      } else {
        reject('No data available');
      }
    });
  });
};

const getBakeryImages = async () => {
  return new Promise((resolve, reject) => {
    onValue(carouselBakeryRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // Convert the data into an array of users with their IDs
        const bakeryImages = Object.keys(data).map((imageId) => ({
          id: imageId, 
          ...data[imageId], 
        }));
        resolve(bakeryImages);
      } else {
        reject('No data available');
      }
    });
  });
};

//get all the messages
/*const getMessages = async () => {
  return new Promise((resolve, reject) => {
    onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // Convert the data into an array of users with their IDs
        const messages = Object.keys(data).map((messageId) => ({
          id: messageId, // Include the user's ID
          ...data[messageId], // Include the user's data
        }));
        resolve(messages);
      } else {
        reject('No data available');
      }
    });
  });
};*/

//get messages in only last month
const getMessages = async () => {
  return new Promise((resolve, reject) => {
    onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const currentDate = new Date();
        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        // Convert the data into an array of messages with their IDs
        const messages = Object.keys(data)
          .map((messageId) => ({
            id: messageId,
            ...data[messageId],
          }))
          .filter((message) => {
            // Check if the message's timestamp is within the last month
            const timestamp = message.timestamp; // Adjust this based on your data structure
            return timestamp >= oneMonthAgo.getTime() && timestamp <= currentDate.getTime();
          });

        resolve(messages);
      } else {
        reject('No data available');
      }
    });
  });
};



export { getUsers, getBreakfastMenu, getLunchMenu, getDinnerMenu, getTeamMembers, getContactDetails,
          getHomeImages, getAboutImages, getBakeryImages, getMessages };
