import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homeout from './components/Homeout';
import Home from './components/Home';
import Employees from './components/Users';
import Breakfast from './components/Breakfast';
import Lunch from './components/Lunch';
import Dinner from './components/Dinner';
import Team from './components/Team';
import Contact from './components/Contact';
import Images from './components/Images';
import Messages from './components/Messages';
import PrivateRoutes from './components/PrivateRoutes';
import AuthDetails from './components/AuthDetails';
import { useState } from 'react';

function App() {
  const authDetails = AuthDetails();
  const currentUser = authDetails.email;
  
  return (
    <>
      {/*<Router>
        <Routes>
          

          {currentUser ? (<>
          <Route path='/home' element={<Home />} />
          <Route path='/users' element={<Employees />} />
          <Route path='/breakfast' element={<Breakfast />} />
          <Route path='/lunch' element={<Lunch />} />
          <Route path='/dinner' element={<Dinner />} />
          <Route path='/team' element={<Team />} /> 
          <Route path='/contact' element={<Contact />} />
          <Route path='/images' element={<Images />} />
          <Route path='/messages' element={<Messages />} /></>)
          :(<>
          <Route path='/' element={<Homeout />} />*/}
          {/*<Route path='/home' element={<Homeout />} />
          <Route path='/users' element={<Homeout />} />
          <Route path='/breakfast' element={<Homeout />} />
          <Route path='/lunch' element={<Homeout />} />
          <Route path='/dinner' element={<Homeout />} />
          <Route path='/team' element={<Homeout />} /> 
          <Route path='/contact' element={<Homeout />} />
          <Route path='/images' element={<Homeout />} />
          <Route path='/messages' element={<Homeout />} />
          </> )
          }
        </Routes>
      </Router>*/}
      <>
      <Router>
        <Routes>
          <Route path="/" element={<Homeout />} />
          
          <Route element={<PrivateRoutes />} >
            <Route path='/home' element={<Home />} />
            <Route path='/users' element={<Employees />} />
            <Route path='/breakfast' element={<Breakfast />} />
            <Route path='/lunch' element={<Lunch />} />
            <Route path='/dinner' element={<Dinner />} />
            <Route path='/team' element={<Team />} /> 
            <Route path='/contact' element={<Contact />} />
            <Route path='/images' element={<Images />} />
            <Route path='/messages' element={<Messages />} />
          </Route>
        </Routes>
      </Router>
    </>
    </>
  );
}

export default App;
