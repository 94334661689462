import React from "react";
import AuthDetails from "./AuthDetails";
import { auth } from "../config/firebase";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import "./custom.css";

const Header = () => {
    const navigate = useNavigate();
    const authDetails = AuthDetails();

    const userSignOut = () => {
        signOut(auth).then(() => {
            navigate('/');
        }).catch((error) => {
            alert(error);
        });
    };

    const usernew = authDetails.fullName;

    return(
        
            <div className="sticky-top header-gold">
                <div className="header-content">
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="header-text">Welcome {usernew}</p>
                        <button className="btn-logout" onClick={userSignOut}>Logout</button>
                    </div>
                </div>
            </div>
  
          
        
        
    );
}

export default Header;