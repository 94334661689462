import React, { useState, useRef, useEffect } from 'react';
import { database, storage } from '../config/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { ref as dbRef, set } from 'firebase/database';
import './custom.css';
import './team.css';

const EditTeamMember = (props) => {
    const [name, setName] = useState('');
    const [companyRole, setCompanyRole] = useState('');
    const [image, setImage] = useState(null);
    const fileInputRef = useRef(null); // Create a ref for the file input

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
        setImage(e.target.files[0]);
        }
    };

    useEffect(() => {
        if (props.member) {
            setName(props.member.name || '');
            setCompanyRole(props.member.company_role || '');
    
        }
    }, [props.member]);
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'name') {
        setName(value);
        }
        if (name === 'companyRole') {
        setCompanyRole(value);
        }
    };

    const handleEditTeamMember = async (e) => {
        e.preventDefault(); // Prevent the default form submission

        if (name && companyRole) {
         try {
            let imageUrl = props.member.imageUrl; // Use the current image URL by default

            // If a new image is selected, upload it to Firebase Storage
            if (image) {
            const storageRef = ref(storage, `${props.teamImagePath}${name}`);
            await uploadBytes(storageRef, image);
            imageUrl = await getDownloadURL(storageRef);
            }

            // Update data in Realtime Database
            const teamMemberRef = dbRef(database, props.teamdb + '/' + props.member.id);

            // Update the existing item
            await set(teamMemberRef, {
            name: name,
            company_role: companyRole,
            imageUrl: imageUrl,
            });

            // Show success message
            alert('Team member edited successfully!');

            // Clear the form
            setName('');
            setCompanyRole('');
            setImage(null);

            // Manually reset the file input value
            fileInputRef.current.value = null;

            // Close the edit form
            props.onMemberEdit();
         } catch (error) {
            // Handle errors
            console.error('Error editing team member:', error);

            // Show error message
            alert('Something went wrong. Please try again.');
         }
        }
    };

    return (
        <div className='add-new'>
        <h2>EDIT TEAM MEMBER</h2>
        <form onSubmit={handleEditTeamMember}>
            <div className='mb-3'>
                <label htmlFor='name' className='form-label'>
                    Name
                </label>
                <input
                    type='text'
                    className='form-control'
                    name='name'
                    value={name}
                    onChange={handleInputChange}
                    required
                />
            </div>
            <div className='mb-3'>
                <label htmlFor='companyRole' className='form-label'>
                    Company Role
                </label>
                <input
                    type='text'
                    className='form-control'
                    name='companyRole'
                    value={companyRole}
                    onChange={handleInputChange}
                    required
                />
            </div>
            <div className='mb-3'>
                <label htmlFor='image' className='form-label'>
                    Image
                </label>
                <input
                    type='file'
                    className='form-control'
                    accept='image/*'
                    onChange={handleImageChange}
                    ref={fileInputRef} // Set the ref for the file input
                />
            </div>
            <div className='mt-4 d-flex justify-content-center'>
                <button type='submit' className='btn-add-new'>
                    EDIT MEMBER
                </button>
            </div>
        </form>
        </div>
  );
};

export default EditTeamMember;
