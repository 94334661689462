import { ref, set } from 'firebase/database';
import { database } from '../config/firebase';

const MakeAdmin = (userId, callback) => {
    const userRoleRef = ref(database, 'users/' + userId + '/role/');

    set(userRoleRef, 'admin')
        .then(() => {
            alert('User role updated to admin successfully!');
            if (callback) {
                callback();
            }
        })
        .catch((error) => {
            alert('Error updating user role in Realtime Database: ' + error.message);
        });
};

const MakeUser = (userId, callback) => {
    const userRoleRef = ref(database, 'users/' + userId + '/role/');

    set(userRoleRef, 'user')
        .then(() => {
            alert('User role updated to user successfully!');
            if (callback) {
                callback();
            }
        })
        .catch((error) => {
            alert('Error updating user role in Realtime Database: ' + error.message);
        });
};

export { MakeAdmin, MakeUser };
