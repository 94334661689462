import React from "react";
import { NavDropdown } from "react-bootstrap";
import "./custom.css";

const Dropdown_custom = (props) => {
    return (
        <NavDropdown className={props.activePath} title={<span className={props.classNames.title}>{props.drop_title}</span>} id="basic-nav-dropdown" >
            {props.drop_items.map(
                (item, index) => <NavDropdown.Item href={props.drop_items_target[index]} className={props.classNames.item}>{item}</NavDropdown.Item>
            )}
        </NavDropdown>
    );
}

export default Dropdown_custom;